/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useAuth } from "@goodgym/auth";

export type AttendeesProps = UI.BoxProps & {
  session: T.SessionPageQuery["session"];
  easySignup?: boolean;
};

const Attendees: React.FC<AttendeesProps> = ({ session, easySignup, ...props }) => {
  const { runner } = useAuth();
  // remove the session leader so we don't show them twice
  const signups = session.signups.filter((s) => s.id !== session.ledBy?.id);

  const taskforce = signups.filter((s) => u.roles.hasRole(s, "TASK_FORCE"));
  const neverRun = signups.filter((s) => s.neverRun);
  const hasRun = signups.filter((s) => !s.neverRun);

  return (
    <UI.Box {...props}>
      {!easySignup && session.ledBy && (
        <>
          <UI.Paper
            sx={{
              display: "flex",
              mt: 2,
              mb: 3,
              alignItems: "flex-start",
              p: 2,
            }}
          >
            <UI.Box sx={{ mr: 2, flexShrink: 0, mt: "4px" }}>
              <UI.RunnerAvatar runner={session.ledBy} />
            </UI.Box>
            <UI.Box sx={{ flexGrow: 1 }}>
              <UI.Typography variant="subtitle1" color="neutral.dark">
                Led by{" "}
                <UI.RunnerLink
                  runner={session.ledBy}
                  variant="subtitle1"
                  color="black.main"
                >
                  {session.ledBy.name}
                </UI.RunnerLink>
              </UI.Typography>

              <UI.Typography
                variant="body1"
                sx={{ whiteSpace: "pre-wrap", mt: 1 }}
              >
                {session.ledBy.bio}
              </UI.Typography>
            </UI.Box>
          </UI.Paper>
        </>
      )}
      {signups.length > 0 && (
        <>
        <UI.Typography variant="h5">Who's going?</UI.Typography>
        <UI.Box sx={{ display: "flex", my: 2, flexWrap: "wrap" }}>
          
          {signups.map((runner) => (
            <UI.Box key={runner.id} sx={{ mr: 1, mb: 1 }}>
              <UI.RunnerAvatar runner={runner} />
            </UI.Box>
          ))}
        </UI.Box></>
      )}
      {!easySignup &&
        u.roles.hasSessionAdmin(runner, session) &&
        taskforce.length > 0 && (
          <UI.Box sx={{ mb: 2 }}>
            <UI.Typography variant="subtitle1">TaskForce</UI.Typography>
            {taskforce.map((runner) => (
              <UI.Chip
                key={runner.id}
                label={runner.name}
                clickable
                to={u.links.profile(runner)}
                component={UI.Link}
                size="small"
                sx={{ mr: 1, mt: 1 }}
              />
            ))}
          </UI.Box>
        )}
      {!easySignup &&
        u.roles.hasSessionAdmin(runner, session) &&
        hasRun.length > 0 && (
          <UI.Box sx={{ mb: 2 }}>
            <UI.Typography variant="subtitle1">
              Been to at least one session
            </UI.Typography>
            {hasRun.map((runner) => (
              <UI.Chip
                key={runner.id}
                label={runner.name}
                clickable
                to={u.links.profile(runner)}
                component={UI.Link}
                sx={{ mr: 1, mt: 1 }}
                size="small"
              />
            ))}
          </UI.Box>
        )}
      {!easySignup &&
        u.roles.hasSessionAdmin(runner, session) &&
        neverRun.length > 0 && (
          <UI.Box sx={{ mb: 2 }}>
            <UI.Typography variant="subtitle1">
              Never been to a session
            </UI.Typography>
            {neverRun.map((runner) => (
              <UI.Chip
                key={runner.id}
                label={runner.name}
                clickable
                to={u.links.profile(runner)}
                component={UI.Link}
                size="small"
                sx={{ mr: 1, my: 1 }}
              />
            ))}
          </UI.Box>
        )}
    </UI.Box>
  );
};

export default Attendees;
