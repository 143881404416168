import * as UI from "@goodgym/components";
import { useSessionPageQuery } from "@goodgym/graphql/hooks";
import * as Icons from "@goodgym/icons";
import * as u from "@goodgym/util";
import { useParams, useSearchParams } from "react-router-dom";
import * as C from "../SessionPage/components";


const SignedUpPage = () => {
  const { slug } = useParams();
  const {  data } = useSessionPageQuery({
    variables: { slug: slug! },
    skip: !slug,
  });


  const [searchParams] = useSearchParams();
  const name = searchParams.get("name");


  if (!data) return <UI.Loading />;
  const { session } = data;

  return (
    <>
      <UI.Box>
        <UI.Box
          sx={{
            height: "130px",
            p: 2,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <UI.Logo height={60} />
        </UI.Box>

        <UI.Container
          sx={{
            minHeight: "500px",
            textAlign: { xs: "center", md: "center" },
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <UI.Box sx={{}}>
            <UI.Typography
              variant="h1"
              sx={{
                mb: 0.5,
                px: 1,
                fontStyle: "normal",
                fontFamily: "'Barlow Condensed', sans-serif",
                textTransform: "uppercase",
                fontSize: { xs: "40px", md: "70px" },
              }}
            >
              We're looking forward to seeing you {name}!
            </UI.Typography>
            <UI.Typography
              variant="h1"
              sx={{
                mb: 0.5,
                px: 1,
                fontStyle: "normal",
                fontFamily: "'Barlow Condensed', sans-serif",
                textTransform: "uppercase",
                fontSize: { xs: "40px", md: "50px" },
              }}
            >
              @{session.title}
            </UI.Typography>
          </UI.Box>
          <UI.Box sx={{ display: "flex" }}>
            <UI.Box
              component="img"
              src={session.imageUrl}
              sx={{
                width: { xs: "auto", md: "auto" },
                maxWidth: "100%",
                height: { xs: "450px", md: "390px" },
                borderRadius: "10px",
                objectFit: "cover",
                mt: 1,
                mx:4
              }}
            />
            <UI.Box>
              <UI.Typography
                variant="h1"
                sx={{
                  mb: 0.5,
                  px: 1,
                  fontStyle: "normal",
                  fontFamily: "'Barlow Condensed', sans-serif",
                  textTransform: "uppercase",
                  mt: 3,
                  fontSize: { xs: "30px", md: "48px" },
                }}
                color="primary"
              >
                {session.organisation?.name}
              </UI.Typography>

              <UI.Box sx={{ display: "flex" }}>
                <Icons.CalendarToday
                  sx={{
                    verticalAlign: "middle",
                    fontSize: { xs: "50px", md: "70px" },
                    mt: 3,
                  }}
                />
                <UI.Box>
                  {" "}
                  <UI.Typography
                    variant="h2"
                    sx={{
                      mb: 0.5,
                      px: 1,
                      fontStyle: "normal",
                      fontFamily: "'Barlow Condensed', sans-serif",
                      textTransform: "uppercase",
                      mt: 3,
                      fontSize: { xs: "30px", md: "34px" },
                    }}
                  >
                    {u.time.format(session.startedAt, "EEEE do MMMM yyyy")}
                  </UI.Typography>
                  <UI.Typography
                    variant="h4"
                    sx={{
                      mb: 0.5,
                      px: 1,
                      fontStyle: "normal",
                      fontFamily: "'Barlow Condensed', sans-serif",
                      textTransform: "uppercase",
                      color: "#F01E28",
                    }}
                  >
                    {u.time.formatTimeRange(
                      session.startedAt,
                      session.duration
                    )}
                  </UI.Typography>
                </UI.Box>
              </UI.Box>
              <UI.Box sx={{ display: "flex" }}>
                <Icons.LocationOn
                  sx={{ verticalAlign: "middle", fontSize: "40px", mt: 3 }}
                />
                <UI.Typography variant="h4" sx={{ mt: 4, ml: 2 }}>
                  {session.area?.name}
                </UI.Typography>
              </UI.Box>
              <UI.Box sx={{textAlign:"left"}}>
                <C.Attendees
                  easySignup={true}
                  session={session}
                  sx={{ my: 3 }}
                />
              </UI.Box>
            </UI.Box>
          </UI.Box>
        </UI.Container>

        <UI.Container sx={{ display: "flex", mb: 3 }}>
          <UI.Box sx={{ width: "100%" }}>
            <UI.Card sx={{ mt: 3, p: 4, mb: 2 }}>
              {session.address && (
                <>
                  <UI.Typography variant="h5" sx={{ mt: 3, mb: 2 }}>
                    Location
                  </UI.Typography>
                  <UI.Grid container spacing={0} sx={{ mb: 3 }}>
                    <UI.Grid item xs={12} sm={6} lg={5}>
                      <UI.Address
                        easySignup={true}
                        address={session.address}
                        noIcon
                      />
                    </UI.Grid>
                    <UI.Grid item xs={12} sm={6} lg={6}>
                      <UI.Typography
                        variant="body2"
                        sx={{ whiteSpace: "pre-wrap" }}
                      >
                        {session.address.otherInformation}
                      </UI.Typography>
                      <UI.Typography
                        variant="body2"
                        sx={{ whiteSpace: "pre-wrap" }}
                      >
                        {session.address.accessNotes}
                      </UI.Typography>
                    </UI.Grid>
                  </UI.Grid>
                  <UI.Box sx={{ mx: { xs: -2, sm: 0 }, zIndex: 0 }}>
                    <C.Map session={session} />
                  </UI.Box>
                </>
              )}
            </UI.Card>
          </UI.Box>
        </UI.Container>
        <UI.Divider sx={{ mx: { xs: -2, sm: 0 } }} />
        <UI.Divider sx={{ mx: { xs: -2, sm: 0 } }} />
      </UI.Box>
    </>
  );
};

export default SignedUpPage;
