/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import * as C from "./components";
import { useSearchParams, useLocation } from "react-router-dom";
import _ from "lodash";

export type SessionSignupDialogProps = UI.DialogProps & {
  open: boolean;
  onClose: () => any;
  session: T.SessionCardFragment & {
    ledBy?: Optional<T.RunnerFragment>;
    risks: T.SessionRiskFragment[];
    taskforceRoles: T.TaskforceRoleFragment[];
    groupFitnessActivities: (T.GroupFitnessActivityFragment & {
      signups: T.RunnerFragment[];
      ledBy: T.RunnerFragment;
    })[];
  };
};

const SessionSignupDialog: React.FC<SessionSignupDialogProps> = ({
  open,
  onClose,
  session,
  ...props
}) => {
  const { data } = T.useSessionSignupDialogQuery();
 
  const [params, setParams] = useSearchParams();
  const theme = UI.useTheme();
  const fullScreen = UI.useMediaQuery(theme.breakpoints.down("md"));
  const runner = data?.runner;

 
const location = useLocation();

const containsSignUp = location.pathname.includes("sign-up");


  // deal with the case where we somehow
  // end up on this dialog even though there's no space
  React.useEffect(() => {
    if (
      open &&
      session.registerMax &&
      session.signups.length >= session.registerMax
    ) {
      onClose();
    }
  }, [session, open, onClose]);

  if (!open) return null;
 
  const updateParams = (key: string, value: string | string[] | null) => {
    const updatedParams = _.pickBy(
      {
        "session-signup": params.get("session-signup"),
        "gfa-id": params.get("gfa-id"),
        "tf-role-id": params.getAll("tf-role-id"),
        [key]: value,
      },
      u.identity
    );
    setParams(updatedParams);
  };

  const groupFitnessActivity = session.groupFitnessActivities.find(
    (gfa) => gfa.id === params.get("gfa-id")
  );

  const taskforceRoles = session.taskforceRoles.filter((r) =>
    params.getAll("tf-role-id").includes(r.id)
  );
 
  const page = (() => {
     if (containsSignUp) {
       return "EASY_SIGNUP";
     }
    if (!runner) {
      return "SIGN_IN";
    } else if (
      u.session.isCommunitySession(session) &&
      !(
        u.training.completed(runner, "COMMUNITY_SESSIONS") ||
        u.training.passed(runner, "COMMUNITY_SESSIONS")
      )
    ) {
      return "GROUP_SESSION_TRAINING";
    } else if (
      session.groupFitnessActivities.length > 0 &&
      !params.get("gfa-id")
    ) {
      return "GFA_SELECT";
    } else if (
      u.roles.hasSessionAdmin(runner, session) &&
      session.taskforceRoles.length > 0 &&
      !params.get("tf-role-id")
    ) {
      return "TASKFORCE_ROLE_SELECT";
    } else {
      return "UNKNOWN";
    }
  })();

  if (page === "SIGN_IN") {
    return (
      <>
          <UI.SignInDialog
            {...props}
            title="Sign in to continue"
            description="You'll need to sign in before you can register for this session."
            open={open}
            onClose={() => null}
          />
       
      </>
    );
  }

  return (
    <UI.Dialog open={open} fullScreen={fullScreen} aria-label="sign up dialog window">
      {(() => {
        switch (page) {
          // case "EASY_SIGNUP":
          //   return (
          //     <C.EasySignUp
          //       aria-label="groupSession"
          //       onClose={onClose}
          //       session={session}
          //     />
          //   );

          case "EASY_SIGNUP":
            return (

                <C.EasySignUp
                aria-label="groupSession"
                onClose={onClose}
                session={session}
              />

            );
          case "GFA_SELECT":
            return (
              <C.GroupFitnessActivitySelect
                session={session}
                onClose={onClose}
                next={(gfaId) => updateParams("gfa-id", gfaId)}
              />
            );
          case "TASKFORCE_ROLE_SELECT":
            return (
              <C.TaskforceRoleSelect
                session={session}
                onClose={onClose}
                back={
                  params.get("gfa-id")
                    ? () => updateParams("gfa-id", null)
                    : onClose
                }
                next={(roleId) => updateParams("tf-role-id", roleId)}
              />
            );
          case "UNKNOWN":
            return (
              <C.Summary
                session={session}
                gfa={groupFitnessActivity}
                taskforceRoles={taskforceRoles}
                onClose={onClose}
              />
            );
          default:
            return null;
        }
      })()}
    </UI.Dialog>
  );
};

export default SessionSignupDialog;
