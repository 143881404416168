/** @jsxImportSource @emotion/react */
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import React from "react";
import {  useParams } from "react-router-dom";
import Training from "../Training";

export type EasySignUpProps = {
  onClose: React.MouseEventHandler;
  session: T.SessionCardFragment;
};

const EasySignUp: React.FC<React.PropsWithChildren<EasySignUpProps>> = ({session,
  onClose,
}) => {



    const params = useParams();
  const slug = params.slug!;
  
  const [name, setName] = React.useState("");
  const [submitted, setSubmitted] = React.useState(false);


  return (
    <>
      <UI.DialogContent sx={{ width: "auto" }}>
        {!submitted && (
          <>
            {" "}
            <UI.DialogTitle sx={{ textAlign: "center" }}>
              Join session: {session.title}!
              <UI.CloseButton onClick={onClose} />
            </UI.DialogTitle>
            <UI.Typography variant="body1" sx={{ my: 2 }}>
              No experience required. Just let us know you're coming, turn up
              and get involved!
            </UI.Typography>
            <UI.Box sx={{ display: "flex" }}>
              <UI.TextField
                color="primary"
                id="outlined-name-input"
                label="Name"
                type="name"
                autoComplete="current-name"
                onChange={(e) => setName(e.target.value)}
                sx={{ mx: 2 }}
              />
              <UI.TextField
                color="primary"
                id="outlined-email-input"
                label="Email"
                type="email"
                autoComplete="current-email"
              />

              <UI.DialogActions sx={{ m: 1 }}>
                <UI.Button
                  fullWidth
                  onClick={() => {
                    setName(name);
                    setSubmitted(true);
                  }}
                >
                 Join Session
                </UI.Button>
              </UI.DialogActions>
            </UI.Box>{" "}
          </>
        )}
        {submitted && (
          <Training
            aria-label="groupSession"
            slug="group-session-safety-briefing"
            onClose={onClose}
            easySignup={true}
            name={name}
            URLslug={slug}
          />
        )}
      </UI.DialogContent>
    </>
  );
};

export default EasySignUp;
