import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import React from "react";
import * as C from "../../../SessionPage/components";


export type LocationCardProps = {
  session: T.SessionPageQuery["session"];
};
const LocationCard:React.FC<LocationCardProps> = ({session}) => {
  return (
    <>
      <UI.Card sx={{ mt: 2, p: 2, mb: 2 }}>
        {session?.address && (
          <>
            <UI.Typography variant="h5" sx={{ mt: 0, mb: 2 }}>
              Location
            </UI.Typography>
            <UI.Grid container spacing={3} sx={{ mb: 3 }}>
              <UI.Grid item xs={12} sm={6} lg={5}>
                <UI.Address
                  easySignup={true}
                  address={session.address}
                  noIcon
                />
              </UI.Grid>
              <UI.Grid item xs={12} sm={6} lg={6}>
                <UI.Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
                  {session.address.otherInformation}
                </UI.Typography>
                <UI.Typography variant="body2" sx={{ whiteSpace: "pre-wrap" }}>
                  {session.address.accessNotes}
                </UI.Typography>
              </UI.Grid>
            </UI.Grid>
            <UI.Box sx={{ mx: { xs: -2, sm: 0 }, zIndex: 0 }}>
              <C.Map session={session} />
            </UI.Box>
          </>
        )}
      </UI.Card>
    </>
  );
}

export default LocationCard