/** @jsxImportSource @emotion/react */
import React from "react";
import * as Icons from "@goodgym/icons";
import * as UI from "@goodgym/components";
import * as T from "@goodgym/graphql/types";
import * as u from "@goodgym/util";
import _ from "lodash";
import { useCompleteTraining } from "@goodgym/graphql/mutations";
import { useNavigate, useParams } from "react-router-dom";

export type TrainingProps = {
  slug: typeof u.training.trainings[keyof typeof u.training.trainings];
  onClose: React.MouseEventHandler;
  easySignup?: boolean;
  name?: string;
  URLslug?: string;
};

const Training: React.FC<React.PropsWithChildren<TrainingProps>> = ({
  slug,
  onClose,
  easySignup,
  name,
  URLslug,
  ...props
}) => {
  const { data } = T.useSessionSignupDialogTrainingQuery({
    variables: { slug },
  });

  const navigate = useNavigate();


  const completeTraining = useCompleteTraining();

  const [currentModule, setCurrentModule] = React.useState(-1);
  const [currentSection, setCurrentSection] = React.useState(0);


  if (!data) return null;
  const { training } = data;

  const totalPages = training.modules.flatMap((m) => m.sections).length;
  const currentPage =
    _.take(training.modules, Math.max(currentModule, 0)).flatMap(
      (m) => m.sections
    ).length +
    currentSection +
    1;

  const next: React.MouseEventHandler = async (e) => {
    console.log("target", e.target);
    e.preventDefault();
    if (currentModule === -1) {
      setCurrentModule(0);
      setCurrentSection(0);
    } else if (
      currentModule >= training.modules.length - 1 &&
      currentSection >= (_.last(training.modules)?.sections.length || 0) - 1
    ) {
      await completeTraining({ training });
      setCurrentModule(-1);
      setCurrentSection(0);

      navigate(`/v3/signed-up/${URLslug}/?name=${name}`);
    } else if (
      currentSection >=
      training.modules[currentModule].sections.length - 1
    ) {
      setCurrentSection(0);
      setCurrentModule(currentModule + 1);
    } else {
      setCurrentSection(currentSection + 1);
    }
  };

  const back: React.MouseEventHandler = (e) => {
    e.preventDefault();
    if (currentModule === -1) {
      onClose(e);
    } else if (currentModule === 0 && currentSection === 0) {
      setCurrentModule(-1);
      setCurrentSection(0);
    } else if (currentSection === 0) {
      setCurrentModule(currentModule - 1);
      setCurrentSection(
        training.modules[currentModule - 1].sections.length - 1
      );
    } else {
      setCurrentSection(currentSection - 1);
    }
  };

  const content = (() => {
    if (currentModule === -1) {
      return (
        <UI.DialogContent sx={{ pb: 1 }}>
          <UI.DialogContentText>
            Before you can register for this GoodGym session you must complete a
            short training module.
          </UI.DialogContentText>
          <UI.Box
            sx={{ mb: 2 }}
            dangerouslySetInnerHTML={{
              __html: training.description,
            }}
          />
        </UI.DialogContent>
      );
    } else if (currentModule < training.modules.length) {
      const mod = training.modules[currentModule];
      const section = mod.sections[currentSection];
      return (
        <UI.DialogContent sx={{ pb: 1 }}>
          <UI.Box key={section.id}>
            <UI.Typography variant="subtitle1">{section.name}</UI.Typography>
            <UI.Box
              key={section.id}
              sx={{ mb: 2 }}
              dangerouslySetInnerHTML={{
                __html: section.description,
              }}
            />
          </UI.Box>
        </UI.DialogContent>
      );
    }
  })();

  return (
    <>
      <UI.DialogTitle>
        {data.training.name}
        <UI.CloseButton onClick={onClose} />

        {currentModule > -1 && (
          <UI.Box sx={{ mt: 2 }}>
            <UI.LinearProgress
              variant="determinate"
              value={(currentPage / totalPages) * 100}
              sx={{ mr: "-36px" }}
            />
            <UI.Typography variant="overline">
              Page {currentPage} of {totalPages}
            </UI.Typography>
          </UI.Box>
        )}
      </UI.DialogTitle>
      {content}
      <UI.DialogActions sx={{ m: 1 }}>
        <UI.Button fullWidth type="submit" onClick={back} variant="text">
          {currentModule === -1 ? "Cancel" : "Back"}
        </UI.Button>
        <UI.Button fullWidth type="submit" onClick={next}>
          {currentPage === totalPages
            ? "Complete"
            : easySignup
            ? "I understand and agree"
            : "Next"}
        </UI.Button>
      </UI.DialogActions>
    </>
  );
};

export default Training;
